import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import instance from '../config/axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../App.css';

import '../signup.css';

function SignIn() {
  let history = useHistory();
  const [user, setUser] = useState({
    email: '',
    password: '',
  });

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmission = async (event) => {
    event.preventDefault();

    let formData = new FormData(event.target);
    
    // Try to fetch IP, but don't let it block signin if it fails
    try {
      const ip_response = await fetch('https://jsonip.com/');
      const ip_data = await ip_response.json();
      formData.append('ip_address', ip_data.ip);
    } catch (error) {
      console.log('Could not fetch IP address:', error);
      // Continue without IP address
    }

    instance({
      method: 'post',
      url: '/login',
      data: formData,
    })
      .then((response) => {
        localStorage.setItem('username', response.data.user.username);
        localStorage.setItem('eFinanceToken', response.data.token);
        localStorage.setItem('eFinanceUser', response.data.user_type);

        if (response.data.user_type === 'Company') {
          localStorage.setItem('company_id', response.data.company_id);
        }

        history.push('/');
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
        console.log(error);
      });
  };

  return (
    <div>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <section className='signup'>
        <div className='container'>
          <div className='col-6 offset-3 mt-5 signup-content'>
            <form
              onSubmit={onSubmission}
              id='signup-form'
              className='signup-form'
            >
              <h2 className='form-title text-center'>Login</h2>

              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  name='user[username]'
                  onChange={onInputChange}
                  placeholder='Username'
                />
              </div>

              <div className='form-group'>
                <input
                  type='password'
                  className='form-control'
                  name='user[password]'
                  onChange={onInputChange}
                  placeholder='Password'
                />
                <span
                  toggle='#password'
                  className='zmdi zmdi-eye field-icon toggle-password'
                ></span>
              </div>

              <div className='form-group'>
                <input type='submit' className='btn btn-primary' />
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SignIn;
