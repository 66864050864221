import React, { useState, useEffect } from 'react';
import instance from '../../config/axios';
import { Link, useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import NavBar from '../../components/NavBar';
import { toast } from 'react-toastify';

const NewTransaction = () => {
  let history = useHistory();

  let { company_id } = useParams();

  const transaction_date = localStorage.getItem('transaction_date');
  const [users, setUsers] = useState([]);
  const [sender_parent, setSenderParent] = useState('');
  const [receiver_parent, setReceiverParent] = useState('');
  const [sender_sub_account, setSenderSubAccount] = useState({});
  const [receiver_sub_account, setReceiverSubAccount] = useState({});
  const [sender_parent_balance, setSenderParentBalance] = useState(0);
  const [receiver_parent_balance, setReceiverParentBalance] = useState(0);
  const [permissions, setPermissions] = useState({});
  const [isDisabled, setDisable] = useState(false);

  useEffect(() => {
    instance({
      method: 'get',
      url: `/companies/${company_id}/users?user_type=Account&is_default=true`,
    })
      .then((response) => {
        setUsers(response.data.users);
        setPermissions(response.data.permissions);
      })
      .catch((error) => {});
  }, []);

  const get_users_sub_accounts_json = () => {
    let users_sub_accounts_json = [];
    users.forEach((single_user) => {
      single_user.sub_accounts.forEach((single_sub_account) => {
        if (single_sub_account.default_account_type === true) {
          users_sub_accounts_json.push({
            label: single_sub_account.account_username,
            value: single_sub_account._id,
            key: single_sub_account._id,
          });
        }
      });
    });

    return users_sub_accounts_json;
  };

  const handleSenderChange = (event) => {
    users.forEach((single_user) => {
      single_user.sub_accounts.forEach((single_sub_account) => {
        if (event.key === single_sub_account._id) {
          setSenderSubAccount(single_sub_account);
          setSenderParent(single_user.username);
          setSenderParentBalance(parseFloat(single_sub_account.balance.$numberDecimal));
        }
      });
    });
  };

  const handleReceiverChange = (event) => {
    users.forEach((single_user) => {
      single_user.sub_accounts.forEach((single_sub_account) => {
        if (event.key === single_sub_account._id) {
          setReceiverSubAccount(single_sub_account);
          setReceiverParent(single_user.username);
          setReceiverParentBalance(parseFloat(single_sub_account.balance.$numberDecimal));
        }
      });
    });
  };

  const sender_dropdown = () => {
    let sender_html = <></>;
    let default_options = get_users_sub_accounts_json();
    let options = [];

    // remove same account from receiver
    default_options.forEach((single_opt, index) => {
      if (single_opt.key === receiver_sub_account._id) {
        // Do Nothing
      } else {
        options.push(single_opt);
      }
    });

    sender_html = (
      <div>
        <label>Sender</label>
        <Select
          defaultValue={options[0]}
          options={options}
          name='transaction[sender_sub_account_id]'
          onChange={(event) => handleSenderChange(event)}
        />
      </div>
    );

    return sender_html;
  };

  const receiver_dropdown = () => {
    let receiver_html = <></>;
    let default_options = get_users_sub_accounts_json();
    let options = [];

    // remove same account from receiver
    default_options.forEach((single_opt, index) => {
      if (single_opt.key === sender_sub_account._id) {
        // Do Nothing
      } else {
        options.push(single_opt);
      }
    });

    receiver_html = (
      <div>
        <label>Receiver</label>
        <Select
          defaultValue={options[0]}
          options={options}
          name='transaction[receiver_sub_account_id]'
          onChange={(event) => handleReceiverChange(event)}
        />
      </div>
    );

    return receiver_html;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setDisable(true);
    let formData = new FormData(event.target);

    instance({
      method: 'post',
      url: `/companies/${company_id}/transactions`,
      data: formData,
    })
      .then((response) => {
        history.push(`/companies/${company_id}/transactions`);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
        setDisable(false);
      });
  };

  const handleAmount = (event) => {
    let current_amount = event.target.value;
    if (current_amount === '') {
      current_amount = 0;
    }
    current_amount = parseFloat(current_amount);

    setSenderParentBalance(parseFloat(sender_sub_account.balance.$numberDecimal) + current_amount);
    setReceiverParentBalance(parseFloat(receiver_sub_account.balance.$numberDecimal) - current_amount);
  };

  const transaction_form = () => {
    let transaction_form_html = (
      <div className='container'>
        <form onSubmit={handleSubmit}>
          <div className='row mt-3'>
            <div className='col-4'>{sender_dropdown()}</div>
            <div className='col-4'>
              <div className='form-group'>
                <label>Amount</label>
                <input
                  type='number'
                  min='1'
                  className='form-control'
                  placeholder='Amount'
                  name='transaction[amount]'
                  disabled={sender_parent === '' || receiver_parent === '' ? true : false}
                  onChange={(event) => handleAmount(event)}
                />
              </div>
            </div>
            <div className='col-4'>{receiver_dropdown()}</div>
          </div>

          <div className='row mb-1'>
            <div className='col-4'>
              <span className='ml-3'>{sender_parent == '' ? '' : `Parent Account: ${sender_parent}`}</span>
            </div>
            <div className='offset-md-4 col-4'>
              <span className='ml-3'>{receiver_parent == '' ? '' : `Parent Account: ${receiver_parent}`}</span>
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-4'>
              <span className='ml-3'>{sender_parent == '' ? '' : `Current Balance: ${new Intl.NumberFormat().format(sender_parent_balance)}`}</span>
            </div>
            <div className='offset-md-4 col-4'>
              <span className='ml-3'>
                {receiver_parent == '' ? '' : `Current Balance: ${new Intl.NumberFormat().format(receiver_parent_balance)}`}
              </span>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <div className='form-group'>
                <label>Narration</label>
                <input type='text' className='form-control' placeholder='Narration' name='transaction[narration]' />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <div className='form-group'>
                <label>Notes</label>
                <input type='text' className='form-control' placeholder='Notes' name='transaction[notes]' />
              </div>
            </div>
          </div>

          <input type='hidden' value={company_id} name='transaction[company_id]' />

          <input type='hidden' value={transaction_date} name='transaction[transaction_date]' />

          <div className='row'>
            <div className='col-12 text-center'>
              <button type='submit' className='btn btn-primary mr-4' disabled={isDisabled}>
                Confirm
              </button>
              <Link to={`/companies/${company_id}/transactions`} className='btn btn-danger'>
                Cancel
              </Link>
            </div>
          </div>
        </form>
      </div>
    );

    return transaction_form_html;
  };

  const render_date_filter = () => {
    let filters_form = (
      <>
        <div className='form-group mb-0'>
          <input className='form-control' defaultValue={transaction_date} disabled />
        </div>
      </>
    );

    return filters_form;
  };

  return (
    <>
      <NavBar show_navs={true} permissions={permissions} />
      <div className='container'>
        <div className='row mt-4 mb-4'>
          <div className='col-3 d-flex'>Transaction ID: {localStorage.getItem('new_transaction_id')}</div>
          <div className='col-6 text-center'>
            <h3>New Transaction</h3>
          </div>
          <div className='col-3 d-flex align-items-center justify-content-end'>{render_date_filter()}</div>
        </div>
      </div>
      {transaction_form()}
    </>
  );
};

export default NewTransaction;
